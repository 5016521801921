$-breakpoints: (
  small: 0,
  medium: 600,
  2cols: 770,
  large: 1092,
  menuMaxWith: 1286,
);

@mixin centeredMaxWidth($max-width: 1286px) {
  max-width: $max-width;
  margin: 0 auto;
}

@mixin thinScroll() {
  --scrollbar-foreground: #564c5c;
  --scrollbar-background: #27192f;

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);

  // Webkit
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border-radius: 20px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }
}

@mixin hideScroll() {
  /* Hide scrollbar webkit */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar Firefox */
  scrollbar-width: none;

  /* Hide scrollbar IE 10+ */
  -ms-overflow-style: none;
}

@mixin breakpoint($breakpoint, $dir: up, $orientation: false) {
  @if map-has-key($-breakpoints, $breakpoint) {
    $value: map-get($-breakpoints, $breakpoint);

    $current-dir: "min-";

    @if $dir== "down" {
      $current-dir: "max-";
      $value: $value - 1;
    }

    @if $value==0 {
      @if $orientation {
        @media (orientation: #{$orientation}) {
          @content;
        }
      } @else {
        @content;
      }
    } @else {
      @if $orientation {
        @media (#{$current-dir}width: #{$value}px) and (orientation: #{$orientation}) {
          @content;
        }
      } @else {
        @media (#{$current-dir}width: #{$value}px) {
          @content;
        }
      }
    }
  } @else {
    @warn "invalid breakpoint: #{$breakpoint}. Valid breakpoints are: #{map-keys($-breakpoints)}";
  }
}

@mixin text-min-max(
  $size-mobile,
  $lh-mobile,
  $size-desktop,
  $lh-desktop,
  $fontFamily,
  $fontWeight,
  $isImportant: false
) {
  $important: if($isImportant, !important, null);
  $min: map-get($-breakpoints, "medium");
  $max: map-get($-breakpoints, "large");
  $diff: $max - $min;
  $size-diff: $size-desktop - $size-mobile;

  font-family: var($fontFamily);
  font-size: $size-mobile * 1px $important;
  font-weight: $fontWeight $important;
  line-height: $lh-mobile $important;

  @if $size-mobile != $size-desktop {
    @include breakpoint(medium) {
      font-size: calc(
        #{$size-mobile * 1px} + #{$size-diff} * ((100vw - #{$min * 1px}) / 680)
      );
      line-height: $lh-desktop $important;
    }
    @include breakpoint(large) {
      font-size: $size-desktop * 1px $important;
    }
  }
}

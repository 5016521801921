@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu {
  display: block;
  background-color: var(--tv2-menu-bg-primary);
  transform: translateY(0);
  transition: transform 0.25s ease-in-out;
  will-change: transform;
  transform-style: flat;

  // while initially a block item the menu transforms into a fixed item and space is allocated
  // on body padding-top. this is to avoid content layout shift when menu loaded
  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--tv2-menu-z-index);
  }

  &--open {
    bottom: 0;
    transform: translateY(0);
  }

  &__top-nav {
    display: grid;
    grid-template-columns: auto repeat(11, minmax(0, 1fr));
    grid-template-rows: [menu-start menu-level-1-start] 1fr [menu-level-1-end menu-end];
    align-items: center;
    @include m.centeredMaxWidth();

    .tv2-menu__context-logo {
      grid-row-start: menu-level-1-start;
      grid-row-end: menu-level-1-end;
      grid-column-start: 1;
      grid-column-end: 1;
    }

    .tv2-menu__active-category {
      grid-row-start: menu-level-1-start;
      grid-row-end: mmenu-level-1-end;
      grid-column-start: 2;
      grid-column-end: 7;
      margin-left: 4px;
    }

    .tv2-menu__categories {
      grid-row-start: menu-level-2-start;
      grid-row-end: menu-level-2-end;
      grid-column-start: 1;
      grid-column-end: 13;
    }

    .tv2-menu__actions {
      grid-row-start: menu-level-1-start;
      grid-row-end: menu-level-1-end;
      grid-column-start: 7;
      grid-column-end: 13;
    }

    .tv2-menu__subcategories {
      grid-row-start: menu-level-2-start;
      grid-row-end: menu-level-2-end;
      grid-column-start: 1;
      grid-column-end: 13;
    }

    .tv2-menu__actions {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    @include m.breakpoint(large) {
      grid-template-rows: [menu-start] 1fr [menu-end];

      .tv2-menu__actions {
        padding-top: 0;
        padding-bottom: 0;
      }

      .tv2-menu__active-category {
        margin-left: 0;
      }

      .tv2-menu__categories, .tv2-menu__active-category {
        grid-row-start: menu-level-1-start;
        grid-row-end: menu-level-1-end;
        grid-column-start: 2;
        grid-column-end: 10;
      }

      .tv2-menu__actions {
        grid-row-start: menu-level-1-start;
        grid-row-end: menu-level-1-end;
        grid-column-start: 10;
        grid-column-end: 13;
      }
    }
  }
}

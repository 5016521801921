@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__auth-label {
  @include m.text-min-max(16, 1.5, 16, 1.5, --tv2-menu-font-family-display, 600);
  color: var(--tv2-menu-color-primary);
  display: none;
  margin-left: 8px;
  @include m.breakpoint(large) {
    display: inline;
  }
}

@use "src/scss/abstracts/mixins/index" as m;
@use "header_section";

.tv2-menu__active-category {
  @extend .tv2-menu__header-section;

  a.tv2-menu__pill {
    &:hover,
    &:focus-visible {
      color: var(--tv2-menu-engage-primary);
      background-color: initial;
    }
  }

  .tv2-menu__pill {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-family: var(--tv2-menu-font-family-display);
    line-height: 1;
    font-weight: 600;
    padding: 13px 8px;

    &--live {
      position: relative;
      padding-left: 30px;

      &:before {
        content: "";
        position: absolute;
        left: 10px;
        top: calc(50% - 5px);
        border-radius: 50%;
        background: #FF0000;
        box-shadow: 0 0 0 0 #FF0000;
        height: 10px;
        width: 10px;
        transform: scale(1);
        animation: pulse-red 2s 3;
      }
    }
  }

  @include m.breakpoint(large) {
    padding-left: 4px;

    .tv2-menu__pill {
      padding: 16px 20px;

      &--live {
        padding-left: 30px;
      }
    }
  }
}


@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__megamenu {
  &__categories {
    @include m.text-min-max(20, 1, 24, 1, --tv2-menu-font-family-text, 300);
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    // As a trick to allow for subcategories to have focus state with padding
    // this space is allocated on the categories container.
    margin: 0 0 0 -12px;
    padding-left: 12px;

    .tv2-menu__megamenu__link {
      @include m.text-min-max(24, 1, 32, 1, --tv2-menu-font-family-display, 600);
    }
    
    .tv2-menu__megamenu__subcategories {
      margin-left: -12px;
    }

    > * {
      margin-bottom: 48px;
    }

    @include m.breakpoint(large) {
      overflow: auto;
      padding-bottom: 32px;
      @include m.thinScroll();
    }
  }
}

@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__auth-bubble {
  @include m.text-min-max(14, 1, 16, 1, --tv2-menu-font-family-display, 600);
  align-items: center;
  background: var(--tv2-menu-color-grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
  color: var(--tv2-menu-color-secondary);

  & > svg {
    fill: var(--tv2-menu-color-offdark);
    width: 18px;
    height: 18px;
  }

  &--authenticated {
    background: var(--tv2-menu-authenticated);
    svg {
      display: none;
    }
  }

  @include m.breakpoint(large) {
    height: 32px;
    width: 32px;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}

$font-family-tv2-display: "TV 2 Display";
$font-family-tv2-text: "TV 2 Text";

@font-face {
  font-family: $font-family-tv2-text;
  src: url("https://www.tv2.no/s/fonts/tv2/WOFF2/TV2Text-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-family-tv2-text;
  src: url("https://www.tv2.no/s/fonts/tv2/WOFF2/TV2Text-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-family-tv2-display;
  src: url("https://www.tv2.no/s/fonts/tv2/WOFF2/TV2Display-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

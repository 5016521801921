@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__context-logo {

  @mixin border-right() {
    &:after {
      content: ' ';
      position: absolute;
      top: 10px;
      bottom: 10px;
      right: 0;
      width: 2px;
      background: #3A2F5C;
    }

    .tv2-menu--open & {
      &:after {
        display: none;
      }
    }
  }

  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
  height: 100%;
  padding: 0 12px;
  color: var(--tv2-menu-color-primary);

  &:hover,
  &:focus {
    color: var(--tv2-menu-color-primary);
  }

  svg {
    height: 25px;
  }

  .tv2-menu--open & {
    border: none;
  }

  .tv2-menu__top-nav--active-menu-entry & {
    @include border-right();
  }

  @include m.breakpoint(large) {
    padding: 0 24px 0 15px;
    svg {
      height: 28px;
    }

    @include border-right();
  }

  &:focus-visible {
    outline: none;

    svg {
      box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
    }
  }
}

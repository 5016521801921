@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__context-link {
  font-family: var(--tv2-menu-font-family-text);
  font-weight: 300;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 40px;
  position: relative;
  z-index: 3;
  padding: 7px 12px;

  svg {
    height: 11px;
    width: 11px;
    margin-right: 8px;
  }

  @include m.breakpoint(large) {
    font-size: 16px;
    padding: 8px 16px;
  }

  &--tv2no {
    &:hover, &:focus-visible {
      color: var(--tv2-menu-color-offdark);
      svg {
        fill: var(--tv2-menu-color-secondary);
      }
    }
  }

  &--tv2play {
    background-color: var(--tv2-menu-color-purplebright);
    color: var(--tv2-menu-color-offdark);
    &:hover, &:focus-visible {
      color: var(--tv2-menu-color-offdark);
    }
  }

  &--alternative {
    background-color: var(--tv2-menu-color-purplebright);
    color: var(--tv2-menu-color-offdark);
    font-weight: 600;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      fill: var(--tv2-menu-color-offdark);
    }

    &:hover,
    &:focus-visible {
      color: var(--tv2-menu-color-offdark);
    }
  }
}

@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__auth-menu {
  display: none;
  background: var(--tv2-menu-color-purplebright);
  border-radius: 8px;
  bottom: -10px;
  right: -46px;
  position: absolute;
  transform: translateY(100%);
  width: 150px;
  z-index: 2;

  &--open {
    display: initial;
  }

  // Whenever menu is minimized the context menu should not be shown
  .tv2-menu--minimized & {
    &--open {
      display: none;
    }
  }

  &:before {
    background: var(--tv2-menu-color-purplebright);
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    right: 56px;
    top: 0;
    transform-origin: bottom;
    transform: rotate(45deg) translate(-50%, -4px);
    width: 10px;
  }

  &__action {
    @include m.text-min-max(16, 1.5, 16, 1.5, --tv2-menu-font-family-display, 600);
    align-items: center;
    color: var(--tv2-menu-color-offdark);
    display: flex;
    margin-top: 12px;
    padding: 0 16px;
    width: 100%;
    border: none;
    background-color: transparent;
    text-decoration: none;

    svg {
      height: 24px;
      margin-right: 8px;
      width: 24px;
      fill: var(--tv2-menu-color-offdark);
    }

    &:last-child {
      border-top: 1px solid rgba(var(--tv2-menu-color-offdark-rgb), 0.3);
      padding: 12px 16px;
    }
  }

  @include m.breakpoint(large) {
    bottom: -12px;
    left: -56px;
    right: auto;

    &:before {
      left: 64px;
      right: auto;
    }
  }
}

@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__megamenu__toggle {
  background: none;
  border: none;
  cursor: pointer;
  margin: 2px 6px 2px 0;
  position: relative;
  height: 28px;
  width: 28px;
  z-index: 3;

  &:before,
  &:after {
    border-bottom: 3px solid var(--tv2-menu-color-primary);
    content: "";
    left: 50%;
    position: absolute;
    top: calc(50% - 1px);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
    width: 14px;
  }

  &:before {
    transform: translate(-50%, -3px);
  }

  &:after {
    transform: translate(-50%, 3px);
  }

  &--open {
    &:before, &:after {
      width: 17px;
    }
    &:before {
      border-color: var(--tv2-menu-color-white);
      transform: translateX(-50%) rotate(-45deg);
    }

    &:after {
      border-color: var(--tv2-menu-color-white);
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
  }

  @include m.breakpoint(large) {
    margin-right: 9px;
  }
}

.tv2-menu__megamenu__close-button {
  position: absolute;
  bottom: 6px;
  right: 6px;
  opacity: 0;
  pointer-events: none;
  appearance: none;
  border: none;

  &:focus-visible,
  &:active {
    opacity: 1;
  }
}

.tv2-menu__header-section {
  display: flex;
  align-items: center;
  gap: 10px;

  &--gap-none {
    @extend .tv2-menu__header-section;
    gap: 0;
  }

  &--gap-sm {
    @extend .tv2-menu__header-section;
    gap: 8px;
  }

  &--gap-md {
    @extend .tv2-menu__header-section;
    gap: 18px;
  }

  &--flex-end {
    @extend .tv2-menu__header-section;
    justify-content: flex-end;
  }
}

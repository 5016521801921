@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__megamenu__personal-entries {
  @include m.text-min-max(20, 1, 24, 1, --tv2-menu-font-family-display, 600);
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 48px;
  }

  @include m.breakpoint(large) {
    margin-bottom: 24px;
    > * {
      margin-bottom: 24px;
    }
  }
}

@use "header_section";
@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    @include m.breakpoint(large) {
      & > * {
        &:not(:last-child) {
          margin-right: 18px;
        }
      }
    }
  }
}

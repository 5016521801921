.tv2-menu__auth-button {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  padding: 0;
  white-space: nowrap;

  &:hover, &:focus-visible {
    cursor: pointer;
    .tv2-menu__auth-label {
      color: var(--tv2-menu-engage-primary);
    }
    .tv2-menu__auth-bubble {
      background: var(--tv2-menu-engage-primary);
    }
  }

  &:focus-visible {
    outline: none;
    .tv2-menu__auth-bubble {
      box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
    }
  }
}

@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__pill {
  color: var(--tv2-menu-color-primary);
  white-space: nowrap;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--tv2-menu-color-primary);
    text-decoration: none;
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
  }

  &--secondary {
    color: var(--tv2-menu-color-offwhite);

    &:hover,
    &:focus-visible,
    &--active {
      color: var(--tv2-menu-color-offwhite);
      background-color: var(--tv2-menu-megamenu-link-active-bg);
    }
  }
}

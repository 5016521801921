.tv2-menu__megamenu {
  .tv2-menu__search {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--tv2-menu-color-input-text-disabled);

    &:focus-within {
      color: var(--tv2-menu-color-primary);
    }

    input {
      width: 100%;
      max-width: 400px;
      padding: 15px 15px 15px 44px;
      border-radius: 12px;
      background: var(--tv2-menu-bg-input-text);
      border: none;
      font-family: var(--tv2-menu-font-family-text);
      font-weight: 300;
      font-size: 16px;
      color: currentColor;
      &::placeholder {
        color: currentColor;
      }

      &:focus-visible, &:active {
        outline: none;
      }
    }

    svg {
      position: absolute;
      left: 16px;
    }
  }
}

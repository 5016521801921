@use "src/scss/abstracts/mixins/index" as m;
@use "header_section";

.tv2-menu {
  &__subcategories-wrapper {
    background: var(--tv2-menu-bg-secondary);

    .tv2-menu--open & {
      display: none;
    }

    // the header#tv2-menu has a padding left to avoid content shift when toggling megamenu and scrollbar is visible
    // we need to compensate for this padding left in the subcategories wrapper to have it take the full width
    @include m.breakpoint(menuMaxWith) {
      margin-left: calc((100vw - 100%)*-1);
      padding-left: calc(100vw - 100%);
    }
  }

  &__subcategories {
    padding-left: 0;
    overflow-x: auto;
    transition: padding-left 0.3s ease-in-out;
    @include m.centeredMaxWidth();
    @extend .tv2-menu__header-section--gap-none;
    @include m.hideScroll();

    .tv2-menu__pill {
      font-size: 14px;
      font-family: var(--tv2-menu-font-family-text);
      font-weight: 300;
      line-height: 1;
      padding: 13px 8px;
      &:first-child {
        padding-left: 12px;
      }

      @include m.breakpoint(large) {
        padding: 15px 12px;
        &:first-child {
          padding-left: 12px;
        }
      }

      &:hover,
      &:focus-visible {
        color: var(--tv2-menu-color-offwhite);
        background-color: var(--tv2-menu-megamenu-link-active-bg);
      }

      &--active {
        color: var(--tv2-menu-color-offwhite);
        background-color: var(--tv2-menu-megamenu-link-active-bg);
      }
    }

    @include m.breakpoint(menuMaxWith) {
      $-active-category-padding-left: 20px;
      $-active-category-wrapper-padding-left: 4px;
      $-tv2-logo-width: 50px;
      $-tv2-logo-border-width: 2px;
      padding-left: calc($-tv2-logo-width + $-tv2-logo-border-width + $-active-category-wrapper-padding-left + $-active-category-padding-left);
      .tv2-menu--minimized & {
        padding-left: 0;
      }
    }
  }
}

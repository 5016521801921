@use "src/scss/abstracts/mixins/index"as m;

.tv2-menu__megamenu {
  &__link {
    text-decoration: none;
    color: var(--tv2-menu-color-white);
    text-decoration: none;

    &:hover,
    &:focus,
    &:focus-visible {
      color: var(--tv2-menu-color-purplebright);
      text-decoration: none;
    }

    &:focus-visible {
      outline: none;
    }

    svg {
      margin-left: 12px;
    }
  }
}

.tv2-menu {
  &__skip {
    position: absolute;
    top: 8px;
    left: 50%;
    font-family: var(--tv2-menu-font-family-text);
    line-height: 1;
    padding: 8px;
    opacity: 0;
    transform: translate(-50%, -150%);
    transition: transform 0.3s ease-in-out;
    z-index: calc(var(--tv2-menu-z-index, 200) + 1);

    &:focus-visible,
    &:active {
      transform: translate(-50%, 0%);
      opacity: 1;
      box-shadow: inset 0 0 0 2px #212DFF;
      background-color: var(--tv2-menu-color-offwhite);
      color: var(--tv2-menu-color-offdark);
    }
  }
}

@font-face {
  font-family: "TV 2 Text";
  src: url("https://www.tv2.no/s/fonts/tv2/WOFF2/TV2Text-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TV 2 Text";
  src: url("https://www.tv2.no/s/fonts/tv2/WOFF2/TV2Text-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TV 2 Display";
  src: url("https://www.tv2.no/s/fonts/tv2/WOFF2/TV2Display-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --tv2-menu-color-complement-text-dark: #cac5dd;
  --tv2-menu-color-offwhite: #fafafa;
  --tv2-menu-color-offdark: #100118;
  --tv2-menu-color-grey: #ededed;
  --tv2-menu-color-greyscale-1: #1f1031;
  --tv2-menu-color-greyscale-2: #3a2f5c;
  --tv2-menu-color-greyscale-7: #261a40;
  --tv2-menu-color-white: #fff;
  --tv2-menu-color-purplebright: #b2aeff;
  --tv2-menu-color-sport-blue: #212dff;
  --tv2-menu-color-offdark-rgb: 16, 1, 24;
  --tv2-menu-color-offwhite-rgb: 250, 250, 250;
  --tv2-menu-avatar-orange: #fcdd8c;
  --tv2-menu-z-index: 501;
  --tv2-menu-font-family-display: "TV 2 Display";
  --tv2-menu-font-family-text: "TV 2 Text";
}

:host {
  --tv2-menu-color-complement-text-dark: #cac5dd;
  --tv2-menu-color-offwhite: #fafafa;
  --tv2-menu-color-offdark: #100118;
  --tv2-menu-color-grey: #ededed;
  --tv2-menu-color-greyscale-1: #1f1031;
  --tv2-menu-color-greyscale-2: #3a2f5c;
  --tv2-menu-color-greyscale-7: #261a40;
  --tv2-menu-color-white: #fff;
  --tv2-menu-color-purplebright: #b2aeff;
  --tv2-menu-color-sport-blue: #212dff;
  --tv2-menu-color-offdark-rgb: 16, 1, 24;
  --tv2-menu-color-offwhite-rgb: 250, 250, 250;
  --tv2-menu-avatar-orange: #fcdd8c;
  --tv2-menu-z-index: 501;
  --tv2-menu-font-family-display: "TV 2 Display";
  --tv2-menu-font-family-text: "TV 2 Text";
}

.tv2-menu {
  --tv2-menu-bg-primary: var(--tv2-menu-color-offdark);
  --tv2-menu-bg-secondary: var(--tv2-menu-color-greyscale-1);
  --tv2-menu-color-primary: var(--tv2-menu-color-offwhite);
  --tv2-menu-color-secondary: var(--tv2-menu-color-offdark);
  --tv2-menu-engage-primary: var(--tv2-menu-color-purplebright);
  --tv2-menu-focus-outline: var(--tv2-menu-color-sport-blue);
  --tv2-menu-border: var(--tv2-menu-color-greyscale-1);
  --tv2-menu-authenticated: var(--tv2-menu-avatar-orange);
  --tv2-menu-megamenu-link-active-bg: var(--tv2-menu-color-greyscale-2);
  --tv2-menu-color-input-text-disabled: var(--tv2-menu-color-complement-text-dark);
  --tv2-menu-bg-input-text: var(--tv2-menu-color-greyscale-7);
}

html[data-theme="tv2-light"] .tv2-menu {
  --tv2-menu-bg-primary: var(--tv2-menu-color-offwhite);
  --tv2-menu-bg-secondary: var(--tv2-menu-color-offwhite);
  --tv2-menu-color-primary: var(--tv2-menu-color-offdark);
  --tv2-menu-color-secondary: var(--tv2-menu-color-offwhite);
  --tv2-menu-engage-primary: var(--tv2-menu-color-purplebright);
  --tv2-menu-focus-outline: var(--tv2-menu-color-sport-blue);
  --tv2-menu-border: #f4f4f4;
  --tv2-menu-authenticated: var(--tv2-menu-avatar-orange);
  --tv2-menu-megamenu-link-active-bg: var(--tv2-menu-color-greyscale-2);
  --tv2-menu-color-input-text-disabled: var(--tv2-menu-color-complement-text-dark);
  --tv2-menu-bg-input-text: var(--tv2-menu-color-greyscale-7);
}

@-webkit-keyframes pulse-red {
  0% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    box-shadow: 0 0 rgba(255, 0, 0, .7);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    box-shadow: 0 0 rgba(255, 0, 0, 0);
  }
}

@keyframes pulse-red {
  0% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    box-shadow: 0 0 rgba(255, 0, 0, .7);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    box-shadow: 0 0 rgba(255, 0, 0, 0);
  }
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding-top: 0;
}

.tv2-menu__megamenu__toggle {
  cursor: pointer;
  z-index: 3;
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  margin: 2px 6px 2px 0;
  position: relative;
}

.tv2-menu__megamenu__toggle:before, .tv2-menu__megamenu__toggle:after {
  border-bottom: 3px solid var(--tv2-menu-color-primary);
  content: "";
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 14px;
  transition: -webkit-transform .3s ease-in-out, transform .3s ease-in-out;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
}

.tv2-menu__megamenu__toggle:before {
  -webkit-transform: translate(-50%, -3px);
  transform: translate(-50%, -3px);
}

.tv2-menu__megamenu__toggle:after {
  -webkit-transform: translate(-50%, 3px);
  transform: translate(-50%, 3px);
}

.tv2-menu__megamenu__toggle--open:before, .tv2-menu__megamenu__toggle--open:after {
  width: 17px;
}

.tv2-menu__megamenu__toggle--open:before {
  border-color: var(--tv2-menu-color-white);
  -webkit-transform: translateX(-50%)rotate(-45deg);
  transform: translateX(-50%)rotate(-45deg);
}

.tv2-menu__megamenu__toggle--open:after {
  border-color: var(--tv2-menu-color-white);
  -webkit-transform: translateX(-50%)rotate(45deg);
  transform: translateX(-50%)rotate(45deg);
}

.tv2-menu__megamenu__toggle:focus-visible {
  box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
  outline: none;
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__toggle {
    margin-right: 9px;
  }
}

.tv2-menu__megamenu__close-button {
  opacity: 0;
  pointer-events: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.tv2-menu__megamenu__close-button:active {
  opacity: 1;
}

.tv2-menu__megamenu__close-button:focus-visible {
  opacity: 1;
}

.tv2-menu__megamenu__link {
  color: var(--tv2-menu-color-white);
  text-decoration: none;
}

.tv2-menu__megamenu__link:hover, .tv2-menu__megamenu__link:focus {
  color: var(--tv2-menu-color-purplebright);
  text-decoration: none;
}

.tv2-menu__megamenu__link:focus-visible {
  color: var(--tv2-menu-color-purplebright);
  text-decoration: none;
}

.tv2-menu__megamenu__link:focus-visible {
  outline: none;
}

.tv2-menu__megamenu__link svg {
  margin-left: 12px;
}

.tv2-menu__megamenu__categories {
  font-family: var(--tv2-menu-font-family-text);
  grid-auto-rows: minmax(min-content, max-content);
  margin: 0 0 0 -12px;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1;
  display: grid;
}

@media (min-width: 600px) {
  .tv2-menu__megamenu__categories {
    font-size: calc(.588235vw + 16.4706px);
    line-height: 1;
  }
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__categories {
    font-size: 24px;
  }
}

.tv2-menu__megamenu__categories .tv2-menu__megamenu__link {
  font-family: var(--tv2-menu-font-family-display);
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
}

@media (min-width: 600px) {
  .tv2-menu__megamenu__categories .tv2-menu__megamenu__link {
    font-size: calc(1.17647vw + 16.9412px);
    line-height: 1;
  }
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__categories .tv2-menu__megamenu__link {
    font-size: 32px;
  }
}

.tv2-menu__megamenu__categories .tv2-menu__megamenu__subcategories {
  margin-left: -12px;
}

.tv2-menu__megamenu__categories > * {
  margin-bottom: 48px;
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__categories {
    --scrollbar-foreground: #564c5c;
    --scrollbar-background: #27192f;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    padding-bottom: 32px;
    overflow: auto;
  }

  .tv2-menu__megamenu__categories::-webkit-scrollbar {
    width: 5px;
  }

  .tv2-menu__megamenu__categories::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border: none;
    border-radius: 20px;
  }

  .tv2-menu__megamenu__categories::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }
}

.tv2-menu__megamenu__subcategories {
  flex-wrap: wrap;
  gap: 0;
  padding: 16px 0 0;
  font-size: 16px;
  display: flex;
}

.tv2-menu__megamenu__secondary-entries {
  font-family: var(--tv2-menu-font-family-display);
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  display: flex;
}

@media (min-width: 600px) {
  .tv2-menu__megamenu__secondary-entries {
    font-size: calc(.588235vw + 16.4706px);
    line-height: 1;
  }
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__secondary-entries {
    font-size: 24px;
  }
}

.tv2-menu__megamenu__secondary-entries > * {
  margin-bottom: 48px;
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__secondary-entries > * {
    margin-bottom: 24px;
  }
}

.tv2-menu__megamenu__personal-entries {
  font-family: var(--tv2-menu-font-family-display);
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  display: flex;
}

@media (min-width: 600px) {
  .tv2-menu__megamenu__personal-entries {
    font-size: calc(.588235vw + 16.4706px);
    line-height: 1;
  }
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__personal-entries {
    font-size: 24px;
  }
}

.tv2-menu__megamenu__personal-entries > * {
  margin-bottom: 48px;
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__personal-entries, .tv2-menu__megamenu__personal-entries > * {
    margin-bottom: 24px;
  }
}

.tv2-menu__megamenu__bottom-entries {
  font-family: var(--tv2-menu-font-family-text);
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  display: block;
}

@media (min-width: 600px) {
  .tv2-menu__megamenu__bottom-entries {
    font-size: calc(.588235vw + 8.47059px);
    line-height: 1;
  }
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__bottom-entries {
    font-size: 16px;
  }
}

.tv2-menu__megamenu__bottom-entries > * {
  margin: 0 16px 16px 0;
  display: inline-block;
}

.tv2-menu__megamenu .tv2-menu__search {
  color: var(--tv2-menu-color-input-text-disabled);
  align-items: center;
  display: flex;
  position: relative;
}

.tv2-menu__megamenu .tv2-menu__search:focus-within {
  color: var(--tv2-menu-color-primary);
}

.tv2-menu__megamenu .tv2-menu__search input {
  background: var(--tv2-menu-bg-input-text);
  font-family: var(--tv2-menu-font-family-text);
  color: currentColor;
  border: none;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  padding: 15px 15px 15px 44px;
  font-size: 16px;
  font-weight: 300;
}

.tv2-menu__megamenu .tv2-menu__search input::-webkit-input-placeholder {
  color: currentColor;
}

.tv2-menu__megamenu .tv2-menu__search input::placeholder {
  color: currentColor;
}

.tv2-menu__megamenu .tv2-menu__search input:active {
  outline: none;
}

.tv2-menu__megamenu .tv2-menu__search input:focus-visible {
  outline: none;
}

.tv2-menu__megamenu .tv2-menu__search svg {
  position: absolute;
  left: 16px;
}

.tv2-menu__megamenu {
  background-color: var(--tv2-menu-color-offdark);
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.tv2-menu--open .tv2-menu__megamenu {
  display: block;
}

.tv2-menu__megamenu__grid {
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-direction: column;
  width: 100%;
  max-width: 1286px;
  margin: 0 auto;
  padding: 0 16px 24px;
  display: flex;
  position: absolute;
  top: 48px;
  bottom: 0;
  left: 50%;
  overflow: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tv2-menu__megamenu__grid::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu__grid {
    flex-flow: wrap;
    grid-template-rows: repeat(12, minmax(0, 1fr));
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 50px;
    display: grid;
    overflow: hidden;
  }
}

.tv2-menu__megamenu__categories {
  padding-top: 24px;
}

.tv2-menu__megamenu .tv2-menu__pill {
  padding: 13px 12px;
}

@media (min-width: 1092px) {
  .tv2-menu__megamenu .tv2-menu__pill {
    border-radius: 6px;
    padding: 15px 12px;
  }

  .tv2-menu__megamenu__categories, .tv2-menu__megamenu__right {
    padding-top: 76px;
  }

  .tv2-menu__megamenu__categories {
    grid-area: 1 / 1 / 13 / 9;
  }

  .tv2-menu__megamenu__right {
    --scrollbar-foreground: #564c5c;
    --scrollbar-background: #27192f;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    grid-area: 1 / 9 / 13 / 13;
    padding-bottom: 16px;
    overflow-y: auto;
  }

  .tv2-menu__megamenu__right::-webkit-scrollbar {
    width: 5px;
  }

  .tv2-menu__megamenu__right::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border: none;
    border-radius: 20px;
  }

  .tv2-menu__megamenu__right::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }
}

.tv2-menu__pill {
  color: var(--tv2-menu-color-primary);
  white-space: nowrap;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tv2-menu__pill:hover, .tv2-menu__pill:focus {
  color: var(--tv2-menu-color-primary);
  text-decoration: none;
}

.tv2-menu__pill:focus-visible {
  box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
  outline: none;
}

.tv2-menu__pill--secondary {
  color: var(--tv2-menu-color-offwhite);
}

.tv2-menu__pill--secondary:hover, .tv2-menu__pill--secondary--active {
  color: var(--tv2-menu-color-offwhite);
  background-color: var(--tv2-menu-megamenu-link-active-bg);
}

.tv2-menu__pill--secondary:focus-visible {
  color: var(--tv2-menu-color-offwhite);
  background-color: var(--tv2-menu-megamenu-link-active-bg);
}

.tv2-menu__context-link {
  font-family: var(--tv2-menu-font-family-text);
  white-space: nowrap;
  z-index: 3;
  border-radius: 40px;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  position: relative;
}

.tv2-menu__context-link svg {
  width: 11px;
  height: 11px;
  margin-right: 8px;
}

@media (min-width: 1092px) {
  .tv2-menu__context-link {
    padding: 8px 16px;
    font-size: 16px;
  }
}

.tv2-menu__context-link--tv2no:hover {
  color: var(--tv2-menu-color-offdark);
}

.tv2-menu__context-link--tv2no:focus-visible {
  color: var(--tv2-menu-color-offdark);
}

.tv2-menu__context-link--tv2no:hover svg, .tv2-menu__context-link--tv2no:focus-visible svg {
  fill: var(--tv2-menu-color-secondary);
}

.tv2-menu__context-link--tv2play {
  background-color: var(--tv2-menu-color-purplebright);
  color: var(--tv2-menu-color-offdark);
}

.tv2-menu__context-link--tv2play:hover {
  color: var(--tv2-menu-color-offdark);
}

.tv2-menu__context-link--tv2play:focus-visible {
  color: var(--tv2-menu-color-offdark);
}

.tv2-menu__context-link--alternative {
  background-color: var(--tv2-menu-color-purplebright);
  color: var(--tv2-menu-color-offdark);
  font-weight: 600;
}

.tv2-menu__context-link--alternative svg {
  fill: var(--tv2-menu-color-offdark);
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.tv2-menu__context-link--alternative:hover {
  color: var(--tv2-menu-color-offdark);
}

.tv2-menu__context-link--alternative:focus-visible {
  color: var(--tv2-menu-color-offdark);
}

.tv2-menu__context-logo {
  z-index: 3;
  color: var(--tv2-menu-color-primary);
  align-items: center;
  height: 100%;
  padding: 0 12px;
  display: flex;
  position: relative;
}

.tv2-menu__context-logo:hover, .tv2-menu__context-logo:focus {
  color: var(--tv2-menu-color-primary);
}

.tv2-menu__context-logo svg {
  height: 25px;
}

.tv2-menu--open .tv2-menu__context-logo {
  border: none;
}

.tv2-menu__top-nav--active-menu-entry .tv2-menu__context-logo:after {
  content: " ";
  background: #3a2f5c;
  width: 2px;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 0;
}

.tv2-menu--open .tv2-menu__top-nav--active-menu-entry .tv2-menu__context-logo:after {
  display: none;
}

@media (min-width: 1092px) {
  .tv2-menu__context-logo {
    padding: 0 24px 0 15px;
  }

  .tv2-menu__context-logo svg {
    height: 28px;
  }

  .tv2-menu__context-logo:after {
    content: " ";
    background: #3a2f5c;
    width: 2px;
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 0;
  }

  .tv2-menu--open .tv2-menu__context-logo:after {
    display: none;
  }
}

.tv2-menu__context-logo:focus-visible {
  outline: none;
}

.tv2-menu__context-logo:focus-visible svg {
  box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
}

.tv2-menu__header-section, .tv2-menu__active-category, .tv2-menu__header-section--flex-end, .tv2-menu__header-section--gap-md, .tv2-menu__header-section--gap-sm, .tv2-menu__header-section--gap-none, .tv2-menu__subcategories, .tv2-menu__categories {
  align-items: center;
  gap: 10px;
  display: flex;
}

.tv2-menu__header-section--gap-none, .tv2-menu__subcategories, .tv2-menu__categories {
  gap: 0;
}

.tv2-menu__header-section--gap-sm {
  gap: 8px;
}

.tv2-menu__header-section--gap-md {
  gap: 18px;
}

.tv2-menu__header-section--flex-end {
  justify-content: flex-end;
}

.tv2-menu__actions {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.tv2-menu__actions > :not(:last-child) {
  margin-right: 12px;
}

@media (min-width: 1092px) {
  .tv2-menu__actions > :not(:last-child) {
    margin-right: 18px;
  }
}

.tv2-menu__categories {
  background: var(--tv2-menu-bg-secondary);
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-left: 0;
  overflow-x: auto;
}

.tv2-menu__categories .tv2-menu__pill {
  font-size: 14px;
  font-family: var(--tv2-menu-font-family-text);
  padding: 13px 8px;
  font-weight: 300;
  line-height: 1;
}

.tv2-menu__categories .tv2-menu__pill:first-child {
  padding-left: 12px;
}

@media (min-width: 1092px) {
  .tv2-menu__categories .tv2-menu__pill {
    font-size: 16px;
    font-family: var(--tv2-menu-font-family-display);
    padding: 16px 20px;
    font-weight: 600;
  }

  .tv2-menu__categories .tv2-menu__pill:first-child {
    padding-left: 20px;
  }
}

.tv2-menu__categories .tv2-menu__pill:hover, .tv2-menu__categories .tv2-menu__pill--active {
  color: var(--tv2-menu-color-offwhite);
  background-color: var(--tv2-menu-megamenu-link-active-bg);
}

.tv2-menu__categories .tv2-menu__pill:focus-visible {
  color: var(--tv2-menu-color-offwhite);
  background-color: var(--tv2-menu-megamenu-link-active-bg);
}

@media (max-width: 1091px) {
  .tv2-menu--disable-mobile-top-menu-categories .tv2-menu__categories {
    display: none;
  }
}

@media (min-width: 1092px) {
  .tv2-menu__categories {
    background: none;
    padding-left: 4px;
  }

  .tv2-menu__categories .tv2-menu__pill:hover, .tv2-menu__categories .tv2-menu__pill--active {
    color: var(--tv2-menu-engage-primary);
    background-color: initial;
  }

  .tv2-menu__categories .tv2-menu__pill:focus-visible {
    color: var(--tv2-menu-engage-primary);
    background-color: initial;
  }
}

.tv2-menu__categories::-webkit-scrollbar {
  display: none;
}

.tv2-menu__subcategories-wrapper {
  background: var(--tv2-menu-bg-secondary);
}

.tv2-menu--open .tv2-menu__subcategories-wrapper {
  display: none;
}

@media (min-width: 1286px) {
  .tv2-menu__subcategories-wrapper {
    margin-left: calc(-100vw + 100%);
    padding-left: calc(100vw - 100%);
  }
}

.tv2-menu__subcategories {
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-width: 1286px;
  margin: 0 auto;
  padding-left: 0;
  transition: padding-left .3s ease-in-out;
  overflow-x: auto;
}

.tv2-menu__subcategories::-webkit-scrollbar {
  display: none;
}

.tv2-menu__subcategories .tv2-menu__pill {
  font-size: 14px;
  font-family: var(--tv2-menu-font-family-text);
  padding: 13px 8px;
  font-weight: 300;
  line-height: 1;
}

.tv2-menu__subcategories .tv2-menu__pill:first-child {
  padding-left: 12px;
}

@media (min-width: 1092px) {
  .tv2-menu__subcategories .tv2-menu__pill {
    padding: 15px 12px;
  }

  .tv2-menu__subcategories .tv2-menu__pill:first-child {
    padding-left: 12px;
  }
}

.tv2-menu__subcategories .tv2-menu__pill:hover {
  color: var(--tv2-menu-color-offwhite);
  background-color: var(--tv2-menu-megamenu-link-active-bg);
}

.tv2-menu__subcategories .tv2-menu__pill:focus-visible {
  color: var(--tv2-menu-color-offwhite);
  background-color: var(--tv2-menu-megamenu-link-active-bg);
}

.tv2-menu__subcategories .tv2-menu__pill--active {
  color: var(--tv2-menu-color-offwhite);
  background-color: var(--tv2-menu-megamenu-link-active-bg);
}

@media (min-width: 1286px) {
  .tv2-menu__subcategories {
    padding-left: 76px;
  }

  .tv2-menu--minimized .tv2-menu__subcategories {
    padding-left: 0;
  }
}

.tv2-menu__active-category a.tv2-menu__pill:hover {
  color: var(--tv2-menu-engage-primary);
  background-color: initial;
}

.tv2-menu__active-category a.tv2-menu__pill:focus-visible {
  color: var(--tv2-menu-engage-primary);
  background-color: initial;
}

.tv2-menu__active-category .tv2-menu__pill {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: var(--tv2-menu-font-family-display);
  padding: 13px 8px;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  overflow: hidden;
}

.tv2-menu__active-category .tv2-menu__pill--live {
  padding-left: 30px;
  position: relative;
}

.tv2-menu__active-category .tv2-menu__pill--live:before {
  content: "";
  background: red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation: 2s 3 pulse-red;
  animation: 2s 3 pulse-red;
  position: absolute;
  top: calc(50% - 5px);
  left: 10px;
  -webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: 0 0 red;
}

@media (min-width: 1092px) {
  .tv2-menu__active-category {
    padding-left: 4px;
  }

  .tv2-menu__active-category .tv2-menu__pill {
    padding: 16px 20px;
  }

  .tv2-menu__active-category .tv2-menu__pill--live {
    padding-left: 30px;
  }
}

.tv2-menu__auth-button {
  white-space: nowrap;
  background: none;
  border: none;
  align-items: center;
  padding: 0;
  display: flex;
}

.tv2-menu__auth-button:hover {
  cursor: pointer;
}

.tv2-menu__auth-button:focus-visible {
  cursor: pointer;
}

.tv2-menu__auth-button:hover .tv2-menu__auth-label, .tv2-menu__auth-button:focus-visible .tv2-menu__auth-label {
  color: var(--tv2-menu-engage-primary);
}

.tv2-menu__auth-button:hover .tv2-menu__auth-bubble, .tv2-menu__auth-button:focus-visible .tv2-menu__auth-bubble {
  background: var(--tv2-menu-engage-primary);
}

.tv2-menu__auth-button:focus-visible {
  outline: none;
}

.tv2-menu__auth-button:focus-visible .tv2-menu__auth-bubble {
  box-shadow: inset 0 0 0 2px var(--tv2-menu-focus-outline);
}

.tv2-menu__auth-label {
  font-family: var(--tv2-menu-font-family-display);
  color: var(--tv2-menu-color-primary);
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  display: none;
}

@media (min-width: 1092px) {
  .tv2-menu__auth-label {
    display: inline;
  }
}

.tv2-menu__auth-bubble {
  font-family: var(--tv2-menu-font-family-display);
  background: var(--tv2-menu-color-grey);
  color: var(--tv2-menu-color-secondary);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: flex;
}

@media (min-width: 600px) {
  .tv2-menu__auth-bubble {
    font-size: calc(.294118vw + 12.2353px);
    line-height: 1;
  }
}

@media (min-width: 1092px) {
  .tv2-menu__auth-bubble {
    font-size: 16px;
  }
}

.tv2-menu__auth-bubble > svg {
  fill: var(--tv2-menu-color-offdark);
  width: 18px;
  height: 18px;
}

.tv2-menu__auth-bubble--authenticated {
  background: var(--tv2-menu-authenticated);
}

.tv2-menu__auth-bubble--authenticated svg {
  display: none;
}

@media (min-width: 1092px) {
  .tv2-menu__auth-bubble {
    width: 32px;
    height: 32px;
  }

  .tv2-menu__auth-bubble > svg {
    width: 24px;
    height: 24px;
  }
}

.tv2-menu__auth-menu {
  background: var(--tv2-menu-color-purplebright);
  z-index: 2;
  border-radius: 8px;
  width: 150px;
  display: none;
  position: absolute;
  bottom: -10px;
  right: -46px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.tv2-menu__auth-menu--open {
  display: initial;
}

.tv2-menu--minimized .tv2-menu__auth-menu--open {
  display: none;
}

.tv2-menu__auth-menu:before {
  background: var(--tv2-menu-color-purplebright);
  content: "";
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
  right: 56px;
  -webkit-transform: rotate(45deg)translate(-50%, -4px);
  transform: rotate(45deg)translate(-50%, -4px);
}

.tv2-menu__auth-menu__action {
  font-family: var(--tv2-menu-font-family-display);
  color: var(--tv2-menu-color-offdark);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  display: flex;
}

.tv2-menu__auth-menu__action svg {
  fill: var(--tv2-menu-color-offdark);
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.tv2-menu__auth-menu__action:last-child {
  border-top: 1px solid rgba(var(--tv2-menu-color-offdark-rgb), .3);
  padding: 12px 16px;
}

@media (min-width: 1092px) {
  .tv2-menu__auth-menu {
    bottom: -12px;
    left: -56px;
    right: auto;
  }

  .tv2-menu__auth-menu:before {
    left: 64px;
    right: auto;
  }
}

.tv2-menu__auth {
  z-index: 3;
  position: relative;
}

.tv2-menu__skip {
  font-family: var(--tv2-menu-font-family-text);
  opacity: 0;
  z-index: calc(var(--tv2-menu-z-index, 200)  + 1);
  padding: 8px;
  line-height: 1;
  transition: -webkit-transform .3s ease-in-out, transform .3s ease-in-out;
  position: absolute;
  top: 8px;
  left: 50%;
  -webkit-transform: translate(-50%, -150%);
  transform: translate(-50%, -150%);
}

.tv2-menu__skip:active {
  opacity: 1;
  background-color: var(--tv2-menu-color-offwhite);
  color: var(--tv2-menu-color-offdark);
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  box-shadow: inset 0 0 0 2px #212dff;
}

.tv2-menu__skip:focus-visible {
  opacity: 1;
  background-color: var(--tv2-menu-color-offwhite);
  color: var(--tv2-menu-color-offdark);
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  box-shadow: inset 0 0 0 2px #212dff;
}

.tv2-menu {
  background-color: var(--tv2-menu-bg-primary);
  will-change: transform;
  -webkit-transform-style: flat;
  transform-style: flat;
  transition: -webkit-transform .25s ease-in-out, transform .25s ease-in-out;
  display: block;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.tv2-menu--fixed {
  z-index: var(--tv2-menu-z-index);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.tv2-menu--open {
  bottom: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.tv2-menu__top-nav {
  grid-template-rows: [menu-start menu-level-1-start] 1fr[menu-level-1-end menu-end];
  grid-template-columns: auto repeat(11, minmax(0, 1fr));
  align-items: center;
  max-width: 1286px;
  margin: 0 auto;
  display: grid;
}

.tv2-menu__top-nav .tv2-menu__context-logo {
  grid-area: menu-level-1-start / 1 / menu-level-1-end / 1;
}

.tv2-menu__top-nav .tv2-menu__active-category {
  grid-area: menu-level-1-start / 2 / mmenu-level-1-end / 7;
  margin-left: 4px;
}

.tv2-menu__top-nav .tv2-menu__categories {
  grid-area: menu-level-2-start / 1 / menu-level-2-end / 13;
}

.tv2-menu__top-nav .tv2-menu__actions {
  grid-area: menu-level-1-start / 7 / menu-level-1-end / 13;
}

.tv2-menu__top-nav .tv2-menu__subcategories {
  grid-area: menu-level-2-start / 1 / menu-level-2-end / 13;
}

.tv2-menu__top-nav .tv2-menu__actions {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 1092px) {
  .tv2-menu__top-nav {
    grid-template-rows: [menu-start] 1fr[menu-end];
  }

  .tv2-menu__top-nav .tv2-menu__actions {
    padding-top: 0;
    padding-bottom: 0;
  }

  .tv2-menu__top-nav .tv2-menu__active-category {
    margin-left: 0;
  }

  .tv2-menu__top-nav .tv2-menu__categories, .tv2-menu__top-nav .tv2-menu__active-category {
    grid-area: menu-level-1-start / 2 / menu-level-1-end / 10;
  }

  .tv2-menu__top-nav .tv2-menu__actions {
    grid-area: menu-level-1-start / 10 / menu-level-1-end / 13;
  }
}

.tv2-menu__placeholder {
  background: repeating-linear-gradient(45deg, #fff, #fff 10px, #eee 10px, #eee 20px);
}

.tv2-menu__placeholder__article {
  background: #eee;
  height: 250px;
  position: relative;
  box-shadow: inset 0 0 0 2px #ddd;
}

.tv2-menu__placeholder__article:after {
  content: "Article Placeholder";
  text-align: center;
  text-transform: uppercase;
  color: #aaa;
  line-height: 1.5;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tv2-menu__placeholder--h-5000 {
  height: 5000px;
}

/*# sourceMappingURL=menu.css.map */

:root,
:host {
  --tv2-menu-color-complement-text-dark: #CAC5DD;
  --tv2-menu-color-offwhite: #fafafa;
  --tv2-menu-color-offdark: #100118;
  --tv2-menu-color-grey: #ededed;
  --tv2-menu-color-greyscale-1: #1F1031;
  --tv2-menu-color-greyscale-2: #3A2F5C;
  --tv2-menu-color-greyscale-7: #261A40;
  --tv2-menu-color-white: #ffffff;
  --tv2-menu-color-purplebright: #b2aeff;
  --tv2-menu-color-sport-blue: #212DFF;
  --tv2-menu-color-offdark-rgb: 16, 1, 24;
  --tv2-menu-color-offwhite-rgb: 250, 250, 250;
  --tv2-menu-avatar-orange: #FCDD8C;
  --tv2-menu-z-index: 501;
  --tv2-menu-font-family-display: "TV 2 Display";
  --tv2-menu-font-family-text: "TV 2 Text";
}

.tv2-menu {
  --tv2-menu-bg-primary: var(--tv2-menu-color-offdark);
  --tv2-menu-bg-secondary: var(--tv2-menu-color-greyscale-1);
  --tv2-menu-color-primary: var(--tv2-menu-color-offwhite);
  --tv2-menu-color-secondary: var(--tv2-menu-color-offdark);
  --tv2-menu-engage-primary: var(--tv2-menu-color-purplebright);
  --tv2-menu-focus-outline: var(--tv2-menu-color-sport-blue);
  --tv2-menu-border: var(--tv2-menu-color-greyscale-1);
  --tv2-menu-authenticated: var(--tv2-menu-avatar-orange);
  --tv2-menu-megamenu-link-active-bg: var(--tv2-menu-color-greyscale-2);
  --tv2-menu-color-input-text-disabled: var(--tv2-menu-color-complement-text-dark);
  --tv2-menu-bg-input-text: var(--tv2-menu-color-greyscale-7);
}

html[data-theme="tv2-light"] {
  .tv2-menu {
    --tv2-menu-bg-primary: var(--tv2-menu-color-offwhite);
    --tv2-menu-bg-secondary: var(--tv2-menu-color-offwhite);
    --tv2-menu-color-primary: var(--tv2-menu-color-offdark);
    --tv2-menu-color-secondary: var(--tv2-menu-color-offwhite);
    --tv2-menu-engage-primary: var(--tv2-menu-color-purplebright);
    --tv2-menu-focus-outline: var(--tv2-menu-color-sport-blue);
    --tv2-menu-border: #f4f4f4;
    --tv2-menu-authenticated: var(--tv2-menu-avatar-orange);
    --tv2-menu-megamenu-link-active-bg: var(--tv2-menu-color-greyscale-2);
    --tv2-menu-color-input-text-disabled: var(--tv2-menu-color-complement-text-dark);
    --tv2-menu-bg-input-text: var(--tv2-menu-color-greyscale-7);
  }
}

.tv2-menu__placeholder {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 10px, #eeeeee 10px, #eeeeee 20px);

  &__article {
    background: #eee;
    height: 250px;
    position: relative;
    box-shadow: inset 0 0 0 2px #ddd;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: 'Article Placeholder';
      text-align: center;
      line-height: 1.5;
      text-transform: uppercase;
      color: #aaa;
    }
  }

  &--h-5000 {
    height: 5000px;
  }
}

@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__megamenu{
  &__bottom-entries {
    @include m.text-min-max(12, 1, 16, 1, --tv2-menu-font-family-text, 300);
    display: block;
    > * {
      display: inline-block;
      margin: 0 16px 16px 0;
    }
  }
}

@use "src/scss/abstracts/mixins/index" as m;
@use "header_section";

.tv2-menu {
  &__categories {
    @extend .tv2-menu__header-section--gap-none;
    padding-left: 0;
    background: var(--tv2-menu-bg-secondary);

    .tv2-menu__pill {
      font-size: 14px;
      font-family: var(--tv2-menu-font-family-text);
      font-weight: 300;
      line-height: 1;
      padding: 13px 8px;
      &:first-child {
        padding-left: 12px;
      }

      @include m.breakpoint(large) {
        font-size: 16px;
        font-family: var(--tv2-menu-font-family-display);
        font-weight: 600;
        padding: 16px 20px;
        &:first-child {
          padding-left: 20px;
        }
      }

      &:hover,
      &:focus-visible,
      &--active {
        color: var(--tv2-menu-color-offwhite);
        background-color: var(--tv2-menu-megamenu-link-active-bg);
      }
    }

    @include m.breakpoint(large, "down") {
      .tv2-menu--disable-mobile-top-menu-categories & {
        display: none;
      }
    }

    @include m.breakpoint(large) {
      background: transparent;
      padding-left: 4px;

      .tv2-menu__pill {
        &:hover,
        &:focus-visible,
        &--active {
          color: var(--tv2-menu-engage-primary);
          background-color: initial;
        }
      }
    }

    overflow-x: auto;

    @include m.hideScroll();
  }
}

@forward "megamenu_toggle";
@forward "megamenu_link";
@forward "megamenu_categories";
@forward "megamenu_subcategories";
@forward "megamenu_secondary_entries";
@forward "megamenu_personal_entries";
@forward "megamenu_bottom_entries";
@forward "megamenu_search";

@use "src/scss/abstracts/mixins/index" as m;

.tv2-menu__megamenu {
  display: none;
  position: fixed;
  background-color: var(--tv2-menu-color-offdark);
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  .tv2-menu--open & {
    display: block;
  }

  &__grid {
    position: absolute;
    transform: translateX(-50%);
    top: 48px;
    left: 50%;
    width: 100%;
    bottom: 0;
    padding: 0 16px 24px;
    display: flex;
    flex-direction: column;
    max-width: 1286px;
    margin: 0 auto;
    overflow: auto;
    @include m.hideScroll();

    @include m.breakpoint(large) {
      overflow: hidden;
      display: grid;
      column-gap: 50px;
      grid-template-columns:repeat(12, minmax(0, 1fr));
      grid-template-rows:repeat(12, minmax(0, 1fr));
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__categories {
    padding-top: 24px;
  }

  .tv2-menu__pill {
    padding: 13px 12px;
  }

  @include m.breakpoint(large) {
    .tv2-menu__pill {
      padding: 15px 12px;
      border-radius: 6px;
    }

    &__categories, &__right {
      padding-top: 76px;
    }

    &__categories {
      grid-row-start: 1;
      grid-row-end: 13;
      grid-column-start: 1;
      grid-column-end: 9;
    }

    &__right {
      grid-row-start: 1;
      grid-row-end: 13;
      grid-column-start: 9;
      grid-column-end: 13;
      padding-bottom: 16px;
      overflow-y: auto;
      @include m.thinScroll()
    }
  }
}
